import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// REDUX
import { Provider } from 'react-redux'
import store from './store'

//REACT ROUTER
import { BrowserRouter } from 'react-router-dom';

/** Ably */
import * as Ably from 'ably';
import { AblyProvider } from 'ably/react';

let client;

try {
  client = Ably.Realtime.Promise({
    authUrl: `${process.env.REACT_APP_PAYLOAD_URL}/ably/authenticate`,
  });
} catch (error) {
  console.log(`Error, Ably Authenticate, ${error}`)
}

ReactDOM.render(
  <AblyProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </BrowserRouter>
    </Provider>
  </AblyProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();